<template>
  <v-app>
    <v-main style="background-color: #F8F4F1;">
      <router-view></router-view>

      <!-- order finished dialog -->
      <v-dialog v-model="is_error" max-width="600">
        <v-card class="text-center align-center">
          <v-card-title class="text-center">
            <v-avatar class="mx-auto" size="100" color="success">
              <v-icon color="white" x-large>
                mdi-check
              </v-icon>
            </v-avatar>
          </v-card-title>
          <v-card-text>
            Your order just finished, Thanks for visiting our restaurant
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn @click="quit" depressed text color="error">
              quit
            </v-btn>

            <v-btn @click="tryAgain" depressed color="primary">
              Scan again
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import ENDPOINTS from "@/plugins/axios/endpoints";
import { mapState } from "vuex";

export default {
  name: "App",
  data: () => ({
    timer: null,
    is_error: false
  }),
  components: {},
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    tryAgain() {
      this.is_error = false;
      this.$router.push("/");
      this.$router.go();
    },
    quit() {
      window.close();
    }
  },
  computed: {
    ...mapState(["Order"])
  },
  mounted() {
    /**
     * check if the current customer has an order then check if this order is finished or not
     */
    const self = this;
    //const order = this.$store.getters["Order/order"];

    this.timer = setInterval(function() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.Order.Order.hasOwnProperty("id"))
        self.axios
          .get(ENDPOINTS.SELF_SERVICE.ORDERS.GET + this.Order.order.id)
          .then(res => {
            if (res.status === 200 && typeof res.data === "object") {
              localStorage.setItem("order", JSON.stringify(res.data));
              localStorage.setItem("ordered", JSON.stringify(res.data.items));
              this.$store.commit("Order/setOrder", res.data);
              this.$store.commit("Order/setOrdered", res.data.items);
            }
          })
          .catch(err => {
            if (
              err &&
              Object.prototype.hasOwnProperty.call(err, "response") &&
              err.response.status === 404
            ) {
              self.$store.commit("Order/clearCard");
              localStorage.clear();
              self.is_error = true;
            }
          });
    }, 10000);
  }
};
</script>
