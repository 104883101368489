const routes = [
  {
    path: "/" /*table: the name/id of scanned table*/,
    name: "TableScanner",
    component: require("@/views/localbee_table/Table/index").default
  },
  {
    path: "/table/:table" /*table: the name/id of scanned table*/,
    name: "Start",
    component: require("@/views/localbee_table/Start/index.vue").default
  },
  {
    path: "/menu",
    name: "Menu",
    component: () =>
      import(
        /* webpackChunkName: "menu" */ "@/views/localbee_table/Menu/index.vue"
      )
  },
  {
    path: "/checkout/:status?",
    name: "Checkout",
    component: () =>
      import(
        /* webpackChunkName: "checkout" */ "@/views/localbee_table/Checkout/index.vue"
      )
  },

  {
    path: "/gift-card",
    name: "GiftCrd",
    component: () =>
      import(
        /* webpackChunkName: "GiftCard" */ "@/views/localbee_table/GiftCard/index.vue"
      )
  },
  {
    path: "/success/:id",
    name: "Succes",
    component: () =>
      import(
        /* webpackChunkName: "succes" */ "@/views/localbee_table/Succes/index.vue"
      )
  }
];
export default routes;
