/**
 * @author Omar IZEM
 * @description Order module allow us to manage orders like selected items, update order ...
 *
 * @Items: selected items by customer (open items or we can sai items are selected but not ordered yet)
 * @Ordered: ordered items (payed items => user already ordered items and he's waiting for preparation)
 * @Order: an object contain all order info like id, date, status ...
 * @Item: selected item => allow us to apply some operations like change qty, set note ....
 * @Note: global order note
 * @Current: allow us to remember selected item (not obligatory to be in the card like @Item)
 * @GiftCard: scanned gift card
 * */

const Order = {
  namespaced: true,
  state: {
    Order: JSON.parse(localStorage.getItem("order")) || {},
    Ordered:
      localStorage.getItem("ordered") !== undefined
        ? JSON.parse(localStorage.getItem("ordered"))
        : [],
    Items: JSON.parse(localStorage.getItem("items")) || [],
    Item: null,
    Note: "",
    Current: null,
    GiftCard:
      (localStorage.getItem("GiftCard") &&
        JSON.parse(localStorage.getItem("GiftCard"))) ||
      null
  },
  getters: {
    /*get scanned gift card*/
    giftCard: state => state.GiftCard || {},
    /*get order details*/
    order: state => state.Order,
    /*get ordered items (if exists)*/
    ordered: state => state.Ordered,
    /*get selected items (card content)*/
    card: state => state.Items,
    /*check if card empty*/
    isCardEmpty: state =>
      (Array.isArray(state.Items) && state.Items.length === 0) ||
      !Array.isArray(state.Items),
    /*calculate card total*/
    total: (state, getters) => {
      if (getters.isCardEmpty) return 0;

      /*calculate sum*/
      let sum = 0;
      sum = state.Items.reduce(
        (acc, value) =>
          acc +
          // items total price
          (value.quantity * parseFloat(value.item.price) +
            //item extras total price
            value.extras.reduce(
              (extras_acc, extra) =>
                extras_acc + parseFloat(extra.sellingPrice) * extra.quantity,
              0
            )),
        0
      );
      /*    state.Items.forEach(elt => {
                                                        sum += elt.quantity * parseFloat(elt.item.price);
                                                    });*/
      if (state.GiftCard && parseFloat(state.GiftCard.balance) > 0)
        sum -= parseFloat(state.GiftCard.balance);

      //to avoid negative values
      if (sum < 0) sum = 0;
      return sum;
    },
    /*check if item is selected //by id*/
    checkItem: state => id => {
      const search = state.Items.find(elt => elt.item.id === id);

      return typeof search === "object";
    },
    /*get selected item*/
    selectedItem: state => state.Item || {},
    /*check if this item is the current selected item*/
    isCurrent: state => id => {
      if (!state.Current || !state.Current.item) return false;
      return state.Current.item.id === id;
    },
    currentQuantity: state => {
      if (!(state.Current && state.Current.item)) return 0;

      return state.Current.quantity;
    },
    /*get order note*/
    note: state => state.Note,
    /**
     * calc total price of select extras
     * @param state
     * @returns {number|T}
     */
    currentExtrasTotal: state => {
      if (
        typeof state.Current === "object" &&
        Array.isArray(state.Current.extras)
      ) {
        return state.Current.extras.reduce(
          (accumulator, value) =>
            accumulator + parseFloat(value.sellingPrice) * value.quantity,
          0
        );
      }
      return 0;
    },
    /**
     * return all selected extras for the current item
     * @param state
     * @returns {T[]|*[]}
     */
    currentExtras: state => {
      if (
        typeof state.Current === "object" &&
        Array.isArray(state.Current.extras)
      )
        return state.Current.extras.map(elt => elt);

      return [];
    },
    isExtrasSelected: state => id => {
      if (
        typeof state.Current === "object" &&
        Array.isArray(state.Current.extras)
      ) {
        //let's search for this item
        const search = state.Current.extras.find(elt => elt.id === id);

        return !!search;
      }

      return false;
    }
  },
  mutations: {
    /**
     * set scanned gift card
     * @param state
     * @param payload
     */
    setGiftCard: (state, payload) => {
      state.GiftCard = payload;
      localStorage.setItem("GiftCard", JSON.stringify(payload));
    },
    /**
     * @description set order object
     * @param state
     * @param order
     */
    setOrder: (state, order) => {
      state.Order = order;
    },
    /*set ordered array*/
    setOrdered: (state, ordered = []) => {
      state.Ordered = ordered;
    },
    /*push item in the ordered array*/
    pushOrdered: (state, item = {}) => {
      if (!Array.isArray(state.Ordered)) state.Ordered = [];

      if (!Object.prototype.isObject(item)) return;

      state.Ordered.push(item);
    },
    /*set Selected items (card)*/
    setCard: (state, items = []) => {
      state.Items = items;

      //add this items to locale storage
      localStorage.setItem("items", JSON.stringify(state.Items));
    },
    /*push single item to card*/
    addToCard: (state, item = {}) => {
      if (!Array.isArray(state.Items)) state.Items = [];

      if (!(typeof item === "object")) return;

      /**
       * let's assign extras to this item
       */
      let extras = [];
      // 1 -- check if this item is the current selected item
      if (window.store.getters["Order/isCurrent"](item.id)) {
        extras = window.store.getters["Order/currentExtras"];
      }

      /*check if the item is already selected*/
      const search = state.Items.find(elt => elt.item.id === item.id);

      if (search === undefined)
        /*new item*/
        state.Items.push({
          quantity: 1,
          item: item,
          extras: extras
        });
      else {
        /*update quantity*/
        const index = state.Items.indexOf(search);

        /*break if no item found*/
        if (index === -1) return;

        Object.assign(state.Items[index], {
          note: search.note,
          quantity: search.quantity + 1,
          item: item
        });
      }

      //add this items to locale storage
      localStorage.setItem("items", JSON.stringify(state.Items));
    },
    /*remove item from card*/
    removeItem: (state, item = {}) => {
      /*search item then get it position/index*/
      const search = state.Items.find(elt => elt.item.id === item.id);
      const index = state.Items.indexOf(search);

      /*break if no item found*/
      if (index === -1) return;

      /*slice the item*/
      state.Items.splice(index, 1);

      //add this items to locale storage
      localStorage.setItem("items", JSON.stringify(state.Items));
    },
    /*change quantity*/
    changeQty: (state, payload = { item: {}, quantity: 1 }) => {
      const item = Object.assign({}, payload.item),
        quantity = payload.quantity;

      /*apply update only if quantity > 0*/
      if (quantity < 1) return;

      /*search item*/
      const search = state.Items.find(elt => elt.item.id === item.id);

      const index = state.Items.indexOf(search);

      /*break if no item found*/
      if (index === -1) return;

      /*update quantity*/
      Object.assign(state.Items[index], {
        quantity: quantity,
        item: item
      });

      //add this items to locale storage
      localStorage.setItem("items", JSON.stringify(state.Items));
    },
    /*select item to use it as current item*/
    selectItem: (state, item) => {
      /*get requested item*/
      const search = state.Items.find(elt => elt.item.id === item.id);
      const index = state.Items.indexOf(search);

      /*break if no item found*/
      if (index === -1) {
        state.Item = null;
      }

      state.Item = Object.assign({}, search);
    },
    /**
     * set current item
     * @param state
     * @param item
     */
    selectCurrent: (state, item) => {
      if (!(typeof item === "object")) return;

      //check if the item is in the card
      const search = state.Items.find(elt => elt.item.id === item.id);

      // unselect the item if already selected
      if (
        state.Current &&
        state.Current.item &&
        state.Current.item.id === item.id
      ) {
        state.Current = null;
        return;
      }

      //item found

      if (search) {
        state.Current = {
          quantity: search.quantity,
          item: { ...search.item },
          extras: search.extras.map(ext => ({ ...ext }))
        };
        return;
      }

      state.Current = Object.assign({
        quantity: 1,
        item: Object.assign({}, item),
        extras: null
      });
    },
    setCurrentQuantity: (state, quantity) => {
      //check if this quantity is valid
      if (quantity <= 0) return;

      //check if current item has value
      if (!(state.Current && state.Current.item)) return;

      //set new quantity
      state.Current.quantity = quantity;
    },
    /**
     * add extras
     * @param state
     * @param extra object
     */
    pushExtrasToCurrent: (state, extra = {}) => {
      //check if there's item already selected
      if (!state.Current) return;

      //reserve space for extras array
      if (!Array.isArray(state.Current.extras)) state.Current.extras = [];

      //check if this extras is already selected
      const search = state.Current.extras.find(elt => elt.id === extra.id);

      if (search) {
        //extras already selected
        const index = state.Current.extras.indexOf(search);

        ++state.Current.extras[index].quantity;

        return;
      }

      //add new extras
      state.Current.extras.push({
        id: extra.id,
        name: extra.name,
        sellingPrice: extra.sellingPrice,
        quantity: 1
      });
    },
    removeExtraFromCurrent: (state, payload) => {
      if (typeof payload !== "object") return;
      if (
        !state.Current ||
        (state.Current && !Array.isArray(state.Current.extras))
      )
        return;
      //check if this extra is already exist
      const search = state.Current.extras.find(
        extra => extra.id === payload.id
      );

      //extra exist
      if (search) {
        const index = state.Current.extras.indexOf(search);

        --state.Current.extras[index].quantity;

        if (state.Current.extras[index].quantity <= 0)
          state.Current.extras.splice(index, 1);
      }
    },
    /*clear card*/
    clearCard: state => {
      state.Items = [];
      state.Current = null;
      state.Item = null;
      localStorage.removeItem("items");
      localStorage.removeItem("GiftCard");
    },
    /*set order note*/
    setOrderNote: (state, note) => {
      state.Note = note;
    }
  },
  actions: {
    /*set item note*/
    itemNote: ({ state, getters }, note = "") => {
      /*let get selected item*/
      const item = (getters.selectedItem && getters.selectedItem.item) || null;
      /*get requested item*/
      const search = state.Items.find(elt => elt.item.id === item.id);
      const index = state.Items.indexOf(search);

      /*break if no item found*/
      if (index === -1) return;

      /*update note*/
      Object.assign(state.Items[index], {
        note: note
      });
    }
  }
};

export default Order;
