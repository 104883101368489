import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi" || "md" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: {
          base: "#F17800",
          lighten3: "#FFBB78"
        },
        secondary: "#F4E9E3",
        tertiary: {
          base: "#DFDFDB",
          lighten3: "#EFEFEE"
        },
        accent: "#546BBE",
        bg: "#F8F4F1"
      }
    }
  }
});
