const ENDPOINTS = {
  SELF_SERVICE: {
    CATEGORIES: {
      GET_ALL: "self-service/categories"
    },
    ITEMS: {
      GAT_ALL: "self-service/items"
    },
    ORDERS: {
      CREATE: "self-service/orders",
      GET: "self-service/orders/"
    }
  },
  ERP: {
    MEALTYPES: {
      GET_ALL: "erp/meal-types"
    },
    ALLERGENS: {
      GET_ALL: "/erp/allergens"
    },
    GIFTCARDS: {
      GET: id => `/erp/giftcards/${id}`
    }
  },
  TABLEPLANER: {
    TABLE: {
      GET_ALL: "/table-planer/tables",
      GET: uuid => "/table-planer/tables/" + uuid,
      FINDBYNAME: name => "/table-planer/tables/name/" + name
    }
  }
};

window.ENDPOINTS = ENDPOINTS;
export default ENDPOINTS;
