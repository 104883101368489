<template>
  <v-container fluid class="fill-height pa-0">
    <qrcode-stream
      @detect="onDetect"
      :torch="true"
      class="rounded-lg"
      @init="onInit"
    >
    </qrcode-stream>

    <!--close this window -->
    <v-btn
      @click="quit"
      class="pa-0 px-0"
      id="close_btn"
      color="error lighten-2"
      rounded
    >
      <v-icon class="mx-0">
        mdi-close
      </v-icon>
    </v-btn>

    <v-dialog v-model="is_error" max-width="600">
      <v-card class="text-center align-center">
        <v-card-title class="text-center">
          <v-avatar class="mx-auto" size="100" color="error">
            <v-icon color="white" x-large>
              mdi-alert-circle-outline
            </v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text>
          {{ this.message }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="quit" depressed text color="error">
            quit
          </v-btn>

          <v-btn @click="tryAgain" depressed color="primary">
            Try again
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "TableScannerComponent",
  data: () => ({
    scanned_url: "",
    is_error: false,
    message: ""
  }),
  methods: {
    quit() {
      window.close();
    },
    tryAgain() {
      this.$router.go(0);
    },
    /**
     *
     * @param name
     * @returns {Promise<void>}
     * @throws {*}
     */
    async getTable(name) {
      await this.axios
        .get(window.ENDPOINTS.TABLEPLANER.TABLE.FINDBYNAME(name))
        .then(res => res.data)
        .catch(() => {
          throw "Sorry this table is not available in our restaurant, please try again";
        });
    },
    async redirectToTable(decodedString) {
      this.scanned_url = decodedString;
      if (decodedString && decodedString.includes("/table/")) {
        /**
         * extract table name
         */
        let url = new URL(decodedString);
        let path = url.pathname;
        const matched = this.$router.match(path).matched;
        const resolved = this.$router.resolve(path);

        if (Array.isArray(matched) && matched.length > 0) {
          //check table validity
          /*eslint no-useless-catch: "error"*/
          //  try {
          await this.getTable(resolved.route.params.table);
          /*          } catch (e) {
            throw e;
          }*/

          this.$store.commit("Order/clearCard");
          localStorage.clear();
          await this.$router.push(path);
          return;
        }
      }
      throw "Sorry this table is not available in our restaurant, please try again";
    },
    async onDetect(promise) {
      try {
        const {
          content // decoded String
        } = await promise;
        await this.redirectToTable(content);
      } catch (error) {
        this.is_error = true;
        this.message = error;
        this.$forceUpdate();
      }
    },
    async onInit(promise) {
      // show loading indicator

      try {
        const { capabilities } = await promise;

        console.log(capabilities);
        // successfully initialized
      } catch (error) {
        if (error.name === "NotAllowedError") {
          alert("NotAllowedError");
        } else if (error.name === "NotFoundError") {
          alert("NotFoundError");
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
          alert("NotSupportedError");
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
          alert("NotReadableError");
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
          alert("OverconstrainedError");
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
          alert("OverconstrainedError");
        }
      } finally {
        // hide loading indicator
      }
    }
  }
};
</script>

<style scoped>
#close_btn {
  position: absolute;
  min-width: 40px !important;
  min-height: 40px !important;
  top: 10px;
  right: 10px;
  z-index: 4000;
}

#scanned_url {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: auto;
  z-index: 4000;
  background: white;
}
</style>
