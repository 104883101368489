<template>
  <v-container fill-height class="justify-center align-center text-center">
    <!-- Start First Step = Welcome Page-->
    <div>
      <h2 class="font-weight-bold">Willkommen zu LocalBee</h2>
      <h4>Deiner Digitalen-Speisekarte</h4>

      <div v-show="hideImgAndBtn === false">
        <img
          v-if="hideImage === false"
          src="@/assets/img/Bee.gif"
          height="200px"
          width="98%"
        />

        <v-btn
          v-if="hideButton === false"
          class="px-14 py-8 rounded-pill primary--text text-weight-bold text-capitalize"
          color="secondary"
          style="font-size: 1.2em;"
          elevation="0"
          v-on:click="nameTextBox()"
        >
          Jetzt Starten
        </v-btn>
      </div>

      <v-sheet
        v-if="hideNameForm === false"
        elevation="0"
        tile
        color="transparent"
        height="200px"
      ></v-sheet>
    </div>
    <!-- Start First Step = Welcome Page-->

    <!--Start Show Card NameTextBox-->
    <v-card
      v-if="hideNameForm === false"
      elevation="0"
      height="300px"
      width="100%"
      color="secondary"
      class="pt-15 px-12"
      style="border-top-right-radius: 16%; border-top-left-radius: 16%; position: fixed; bottom: 0%;"
    >
      <h4 class="primary--text mb-5 text-left">
        Wie ist dein Name?
      </h4>
      <v-form @submit.prevent="() => false" lazy-validation ref="name_form">
        <v-text-field
          v-model="party"
          placeholder="Name"
          class="rounded-lg"
          filled
          :rules="[v => !!v]"
          rounded
          v-on:keyup.enter="setParty()"
        >
        </v-text-field>
        <v-btn
          @click="setParty()"
          :disabled="!!!party"
          elevation="0"
          block
          large
          color="primary"
        >
          Next
        </v-btn>
      </v-form>
    </v-card>
    <!--End Show Card NameTextBox-->
  </v-container>
</template>

<script>
export default {
  name: "Start",

  components: {},
  data: () => ({
    hideButton: true,
    hideImage: true,
    hideNameForm: true,
    hideImgAndBtn: false,

    party: ""
  }),

  mounted() {
    this.startLoad();
  },

  methods: {
    /*save scanned table in the vuex/localeStorage*/
    setTable() {
      // QUICK FIX: TODO: @IZEM NEED TO CHANGE IT
      localStorage.removeItem("order");
      localStorage.removeItem("ordered");
      this.$store.commit("Order/setOrder", {});
      this.$store.commit("Order/setOrdered", []);

      /*vuex state calls: Table*/
      this.$store.commit("Table/setTable", this.$route.params.table);
    },
    /*save typed name as party name*/
    setParty() {
      /*check name*/
      if (!this.$refs.name_form.validate()) return;

      /*set party*/
      this.$store.commit("Table/setParty", this.party);

      /*redirect to Menu*/
      this.$router.push("/menu");
    },
    //That to show bee animation and button in start
    startLoad() {
      setInterval(() => {
        this.hideImage = false;
        setInterval(() => {
          this.hideButton = false;
        }, 2000);
      }, 2000);
    },

    //That to show NameTextBox Dialog and hide Jetzt Starten button
    nameTextBox() {
      /*save scanned table*/
      this.setTable();
      this.hideImage = true;
      this.hideButton = true;
      this.hideNameForm = false;
      this.hideImgAndBtn = true;
    },

    //That to redirect to welcome After enter the name
    nameValidation() {
      this.$router.push({ name: "welcome" });
      localStorage.name = this.party;
    }
  }
};
</script>
