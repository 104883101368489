import Vue from "vue";
import Vuex from "vuex";

/*import vuex modules*/
import Table from "@/store/localbee_table/Table";
import Order from "@/store/localbee_table/Order";
import Filter from "@/store/localbee_table/Filter";

Vue.use(Vuex);

const Store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Table,
    Order,
    Filter
  }
});

window.store = Store;
export default Store;
