<template>
  <table-scanner-component />
</template>

<script>
import TableScannerComponent from "@/components/localbee_table/table/TableScannerComponent";
export default {
  name: "TableScannerView",
  components: { TableScannerComponent }
};
</script>

<style scoped></style>
