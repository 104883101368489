/**
 * @author Omar IZEM @3POS
 *@description Table module where we can store table info like name, party name ...
 * */

const Table = {
  namespaced: true,
  state: {
    name:
      localStorage.getItem("table") ||
      "" /*getting table from storage in case the user reload page*/,
    //Party: is typed name by the customer (we're using customer name as party name)
    Party: localStorage.getItem("party") || "" /*the same here*/
  },
  getters: {
    table: state => state.name /*table name getter*/,
    party: state => state.Party /*party name getter*/
  },
  mutations: {
    setTable: (state, name) => {
      state.name = name;
      localStorage.setItem("table", name);
    },
    setParty: (state, party) => {
      state.Party = party;
      localStorage.setItem("party", party);
    },
    checkPerson: (state, payload) => {
      console.log(payload);
    }
  }
};

export default Table;
