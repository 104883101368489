import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "@/plugins/bootstrap-vue";
import "@/plugins/axios/index";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import "@/plugins/currency";

//register qr scanner globally
import VueQrcodeReader from "vue-qrcode-reader";

Vue.use(VueQrcodeReader);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
